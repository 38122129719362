import React, { useEffect, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SvgIcon from '@mui/material/SvgIcon';
import TradeDrawer from "./Drawer"
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { useStyles } from "./style"
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppConfig } from "../../settings/index";
import axios from "axios";
import moment from "moment/moment";
import { ChartCalulation } from "./chartCalculation"
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CachedIcon from '@mui/icons-material/Cached';

const StrategyBuilder = () => {
    const selectedTrade = JSON.parse(localStorage.getItem("selectedTrade")) === null ? [] : JSON.parse(localStorage.getItem("selectedTrade"));
    const [stock, setStock] = useState('NIFTY');
    const [expiryDate, setExpiryDate] = useState('');
    const [expiryDateList, setExpiryDateList] = useState([]);
    const [builderData, setBulderData] = useState([]);
    const [nearestStrike, setNearestStrike] = useState();
    const [selectTrade, setSelectTrade] = useState(selectedTrade);
    const [xChart_data, setXChartData] = useState([]);
    const [yChart_data, setYChartData] = useState([]);
    const [zChart_data, setZChartData] = useState([]);
    const [todayPnL, setTodayPnL] = useState(0);
    const [breakEven, setBreakEven] = useState('');
    const [totalMargin, setTotalMargin] = useState(0);
    const [netCredit, setNetCredit] = useState(0);
    const [maxProfit, setMaxProfit] = useState('');
    const [maxLoss, setMaxLoss] = useState('');
    const [optionPOP, setOptionPOP] = useState('');
    const [isChart, setIsChart] = useState(true);
    const classes = useStyles();

    const handleChangeStock = (event) => {
        if (window.confirm("Are you sure, you want to change Index? This will remove all your current positions.")) {
            setSelectTrade([]);
            setBulderData([])
            setExpiryDate('')
            localStorage.setItem("selectedTrade", JSON.stringify([]));
            let current_stock = event.target.value
            getExpiryDates(current_stock)
            setStock(current_stock);
            // getBuilderData(current_stock, expiryDate)
        }
    };

    const getExpiryDates = (current_stock) => {
        const reqObj = {
            stock: current_stock,
        };
        axios.post(`${AppConfig.clientApiURL}get_expiry_date`, reqObj)
            .then(res => {
                console.log(res)
                let expiryList = res.data.expiry_date_list
                setExpiryDateList(expiryList)
                if (expiryDate !== expiryList[0]) {
                    setExpiryDate(expiryList[0])
                    getBuilderData(stock, expiryList[0])
                }

            })
            .catch(error => {
                console.log(error)
            });
    };

    const getBuilderData = (current_stock, date) => {
        const reqObj = {
            stock: current_stock,
            expiry_date: date
        };
        axios.post(`${AppConfig.clientApiURL}get_oi_chain_builder`, reqObj)
            .then(res => {
                // console.log(res)
                let dataList = res.data.data_list
                setBulderData(dataList)
                setNearestStrike(res.data.nearest_strike)
            })
            .catch(error => {
                console.log(error)
            });
    };



    useEffect(() => {
        getExpiryDates(stock)
    }, []);

    useEffect(() => {
        if (selectTrade.length > 0) {
            let chart_cal = ChartCalulation(selectTrade, selectTrade[0].spot_price)
            setXChartData(chart_cal.x_value_data)
            setYChartData(chart_cal.y_value_data)
            setZChartData(chart_cal.z_value_data)
            setTodayPnL(chart_cal.today_pnl)
            setBreakEven(chart_cal.breakeven)
            setNetCredit(chart_cal.net_credit)
            let max_loss = Math.min.apply(Math, chart_cal.y_value_data)
            setMaxLoss(max_loss < (-chart_cal.total_margin) ? 'Undefined' : '₹' + max_loss)
            let max_profit = Math.max.apply(Math, chart_cal.y_value_data)
            setMaxProfit(max_profit > chart_cal.total_margin ? 'Undefined' : '₹' + max_profit)
            setOptionPOP(chart_cal.P_O_P)
            setIsChart(chart_cal.isChartView)
            if (chart_cal.is_sell_margin) {
                var margin_obj = []
                for (let i = 0; i < selectTrade.length; i++) {
                    if (selectTrade[i].isChecked === true){
                    let data = {
                        contract: stock + "-" + moment(selectTrade[i].expiry).format('DDMMMYY').toUpperCase(),
                        exchange: 'NFO',
                        optionType: selectTrade[i].type === 'CE' ? "CALL" : 'PUT',
                        product: "OPTION",
                        qty: selectTrade[i].lot * 25,
                        strikePrice: selectTrade[i].strike_price,
                        tradeType: selectTrade[i].sell_buy === 'B' ? "BUY" : 'SELL'
                    }
                    margin_obj.push(data)
                }}
                const reqObj = {
                    position: margin_obj
                };
                axios.post(`https://margin-calc-arom-prod.angelbroking.com/margin-calculator/SPAN`, reqObj)
                    .then(res => {
                        let margin1 = res.data.margin.totalMargin
                        setTotalMargin(margin1)
                    })
                    .catch(error => {
                        console.log(error)
                    });
            } else {
                setTotalMargin(chart_cal.total_margin)
            }
        }
    }, [selectTrade]);

    const deleteSelectedTrade = (id) => {
        let trade_data = selectTrade.filter(a => a.id !== id);
        setSelectTrade([...trade_data]);
        localStorage.setItem("selectedTrade", JSON.stringify(trade_data));
    }

    const customTrades = (id, value, key, value2) => {
        if (key === 'isChecked'){
            let trade_data = selectTrade.filter(a => a.id === id);
            trade_data[0].isChecked = value === true ? false:true
            setSelectTrade([...selectTrade]);
        }else if (key === 'b/s'){
            let trade_data = selectTrade.filter(a => a.id === id);
            trade_data[0].sell_buy = value === 'B'?'S':'B'
            setSelectTrade([...selectTrade]);
        }else if (key === 'type'){
            let trade_data = selectTrade.filter(a => a.id === id);
            trade_data[0].type = value === 'CE'?'PE':'CE'
            setSelectTrade([...selectTrade]);
        }else if (key === 'lot'){
            let trade_data = selectTrade.filter(a => a.id === id);
            trade_data[0].lot = value.target.value
            setSelectTrade([...selectTrade]);      
        }else if (key === 'price'){
            let trade_data = selectTrade.filter(a => a.id === id);            
            let new_price = value.target.value
            trade_data[0].price =  new_price === ''?0:new_price
            setSelectTrade([...selectTrade]);
        }else if (key === 'strike_price'){
            let chain_list = builderData.filter(a => a.strike_price === value);  
            if (chain_list.length>0){    
                let d1 = new Date(chain_list[0].expiry_date);
                let d2 = new Date(moment(value2).format('DD-MMM-YYYY'));
                let expiry_same = d1.getTime() === d2.getTime();
                if (expiry_same){
                    let trade_data = selectTrade.filter(a => a.id === id);   
                    trade_data[0].IV =  trade_data[0].type === 'CE'? chain_list[0].call_IV:chain_list[0].put_IV
                    trade_data[0].price = trade_data[0].type === 'CE'? chain_list[0].call_ltp:chain_list[0].put_ltp
                    trade_data[0].strike_price =  chain_list[0].strike_price
                    setSelectTrade([...selectTrade]);
                }else{
                    const reqObj = {
                        stock: stock,
                        expiry_date: moment(value2).format('DD-MMM-YYYY'),
                        strike_price: value
                    };
                    axios.post(`${AppConfig.clientApiURL}get_oi_chain_builder`, reqObj)
                        .then(res => {                 
                            let dataList = res.data.data_list
                            if (dataList.length>0){     
                                let trade_data = selectTrade.filter(a => a.id === id);   
                                trade_data[0].IV =  trade_data[0].type === 'CE'? dataList[0].call_IV:dataList[0].put_IV
                                trade_data[0].price = trade_data[0].type === 'CE'? dataList[0].call_ltp:dataList[0].put_ltp
                                trade_data[0].strike_price =  dataList[0].strike_price
                                setSelectTrade([...selectTrade]);
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        });
                }

            }
        }else if (key === 'expiry'){            
            const reqObj = {
                stock: stock,
                expiry_date: moment(value.target.value).format('DD-MMM-YYYY'),
                strike_price: value2
            };
            axios.post(`${AppConfig.clientApiURL}get_oi_chain_builder`, reqObj)
                .then(res => {                 
                    let dataList = res.data.data_list
                    if (dataList.length>0){     
                        let trade_data = selectTrade.filter(a => a.id === id);   
                        trade_data[0].IV =  trade_data[0].type === 'CE'? dataList[0].call_IV:dataList[0].put_IV
                        trade_data[0].price = trade_data[0].type === 'CE'? dataList[0].call_ltp:dataList[0].put_ltp
                        trade_data[0].expiry =  dataList[0].expiry_date
                        setSelectTrade([...selectTrade]);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    const deleteAllTrade = () => {
        setSelectTrade([]);
        localStorage.setItem("selectedTrade", JSON.stringify([]));
    }
    const resetCustomTrades = () => {
        setSelectTrade(JSON.parse(localStorage.getItem("selectedTrade")));
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container sx={{ maxWidth: '1600px', mt: 4, mb: 4 }} maxWidth={false}>
                    <Grid container spacing={3} >
                        <Grid item xs={5} md={5} lg={5}>
                            <Paper >
                                <Select
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        height: 50,
                                        mt: 4, mb: 4
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={stock}
                                    onChange={handleChangeStock}
                                >
                                    <MenuItem value={'NIFTY'}>NIFTY</MenuItem>
                                    <MenuItem value={'BANKNIFTY'}>BANKNIFTY</MenuItem>
                                    <MenuItem value={'FINNIFTY'}>FINNIFTY</MenuItem>
                                </Select>
                            </Paper>
                            {(selectTrade.length > 0) ?
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: 200 }}>

                                    <Stack direction="row" spacing={40} sx={{ marginBottom: 1 }}>
                                        <Typography variant="body1" fontWeight={600} color="text.primary">
                                            New strategy
                                        </Typography>
                                        <Typography variant="body2" fontSize={16} fontWeight={600} color="text.secondary" mt={1}>
                                            <Link sx={{textDecoration: 'none', cursor:'pointer'}} onClick={deleteAllTrade}>Clear All Trades&nbsp;</Link><br/>
                                            <Link sx={{marginTop: '10px', display:'flex', textDecoration: 'none', cursor:'pointer'}}  fontSize={13}  onClick={resetCustomTrades}>
                                            <CachedIcon></CachedIcon>&nbsp;Reset Price</Link>
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" spacing={5} sx={{ marginLeft: 5 }}>
                                        <Typography variant="body1" color="text.secondary">
                                            B/S
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" >
                                            Expiry
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: '90px !important' }}>
                                            Strike
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: '70px !important' }}>
                                            Type
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: '20px !important' }}>
                                            Lots
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ marginLeft: '35px !important' }}>
                                            Price
                                        </Typography>
                                    </Stack>
                                    {selectTrade.map((tradeData, i) => {
                                        return (<>
                                            <Stack direction="row" spacing={2} mt={1} key={i}>
                                                <Checkbox defaultChecked={tradeData.isChecked} size="small" sx={{ padding: '1px' }} onClick={e => customTrades(tradeData.id, tradeData.isChecked, 'isChecked', '')} />
                                                <Typography variant="body1" className={`${classes.bsButton} ${tradeData.sell_buy === 'B' ? classes.buybtnColor : classes.sellbtnColor}`}
                                                onClick={e => customTrades(tradeData.id, tradeData.sell_buy, 'b/s', '')}>
                                                    {tradeData.sell_buy}
                                                </Typography>
                                                <Select
                                                    sx={{ height: 25 }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={moment(tradeData.expiry).format('D MMM YY')}
                                                   onChange={e => customTrades(tradeData.id, e, 'expiry', tradeData.strike_price)}
                                                >
                                                    {expiryDateList.map((data, i) => {
                                                        return (
                                                            <MenuItem key={i} value={moment(data).format('D MMM YY')}>{moment(data).format('D MMM YY')}</MenuItem>
                                                        );
                                                    })}
                                                </Select>

                                                <ButtonGroup>
                                                    <RemoveIcon onClick={e => customTrades(tradeData.id, tradeData.strike_price-50, 'strike_price', tradeData.expiry)} fontSize="small" className={classes.remove_btn} />
                                                <TextField
                                                    id="outlined-basic"
                                                    hiddenLabel
                                                    readOnly
                                                    value={tradeData.strike_price}
                                                    InputProps={{ style: { height: 25, width: 80, borderRadius: "0px"}}}        
                                                />
                                                <AddIcon onClick={e => customTrades(tradeData.id, tradeData.strike_price+50, 'strike_price', tradeData.expiry)} fontSize="small" className={classes.add_btn}/>
                                                </ButtonGroup>
                                                 <Typography variant="body1" className={classes.tradeType} onClick={e => customTrades(tradeData.id, tradeData.type, 'type', '')}>
                                                    {tradeData.type}
                                                </Typography>

                                                <Select
                                                    sx={{ height: 25 }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={tradeData.lot}
                                                    onChange={e => customTrades(tradeData.id, e, 'lot', '')}
                                                >
                                                    {Array.from(Array(150), (e, i) => {
                                                        var nos = 1 + i
                                                        return <MenuItem key={i} value={nos}>{nos}</MenuItem>
                                                    })}

                                                </Select>
                                                <TextField
                                                    hiddenLabel
                                                    value={tradeData.price}
                                                    InputProps={{ style: { height: 25, width: 70 } }}
                                                    onChange={e => customTrades(tradeData.id, e, 'price', '')}
                                                />
                                                <DeleteIcon className={classes.deleteIcon} onClick={e => deleteSelectedTrade(tradeData.id)} />
                                            </Stack>
                                            <br />
                                        </>
                                        );
                                    })}

                                    <TradeDrawer buttonName='Add/Edit'
                                        stock={stock}
                                        setStock={setStock}
                                        builderData={builderData}
                                        expiryDateList={expiryDateList}
                                        expiryDate={expiryDate}
                                        setExpiryDate={setExpiryDate}
                                        handleChangeStock={handleChangeStock}
                                        nearestStrike={nearestStrike}
                                        getBuilderData={getBuilderData}
                                        setSelectTrade={setSelectTrade}
                                        selectTrade={selectTrade}

                                    />

                                </Paper>
                                : <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 200 }}>
                                    <SvgIcon style={{ marginLeft: '200px', width: "135px", height: "82px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 82">
                                        <g fill="none" fillRule="evenodd"><g fillRule="nonzero"><ellipse cx="71" cy="42" fill="#E5EFEF" opacity=".498" rx="39" ry="40"></ellipse>
                                            <g><path fill="#FFF" d="M38.4 1.18H3.554c-1.283 0-2.323 1.057-2.323 2.36v57.828c0 1.303 1.04 2.36 2.323 2.36h41.813c1.283 0 2.323-1.057 2.323-2.36V10.622L38.399 1.18z" transform="translate(15)"></path>
                                                <path fill="#AAA" d="M45.368 64.908H3.555c-1.922 0-3.485-1.588-3.485-3.54V3.54C.07 1.588 1.633 0 3.555 0h34.844c.308 0 .604.124.821.345l9.292 9.441c.218.221.34.521.34.835v50.746c0 1.952-1.563 3.54-3.484 3.54zM3.555 2.361c-.64 0-1.162.529-1.162 1.18v57.827c0 .65.521 1.18 1.162 1.18h41.813c.64 0 1.162-.53 1.162-1.18V11.11l-8.612-8.75H3.555z" transform="translate(15)"></path></g>
                                            <g><path fill="#EFEFD2" d="M1.259 10.808L10.757 10.808 1.259 1.31z" transform="translate(52)"></path>
                                                <path fill="#AAA" d="M10.757 11.996H1.26c-.656 0-1.187-.531-1.187-1.188V1.31c0-.48.29-.913.733-1.096.444-.186.953-.082 1.293.257l9.498 9.498c.34.34.442.85.258 1.294-.184.444-.617.733-1.097.733zM2.447 9.62H7.89L2.446 4.176v5.445z" transform="translate(52)"></path></g>
                                            <g fill="#AAA" transform="translate(0 22)"><path d="M4.404 20.98c-.29 0-.582-.118-.804-.354L.382 17.22c-.444-.47-.444-1.233 0-1.703.445-.47 1.165-.47 1.61 0l3.217 3.407c.444.47.444 1.233 0 1.703-.222.236-.513.353-.805.353z"></path>
                                                <path d="M1.187 20.98c-.291 0-.583-.118-.805-.354-.444-.47-.444-1.233 0-1.703L3.6 15.516c.444-.47 1.164-.47 1.609 0 .444.47.444 1.233 0 1.703L1.99 20.626c-.222.236-.513.353-.804.353z"></path>
                                                <ellipse cx="10.76" cy="14.457" rx="1.138" ry="1.205"></ellipse><ellipse cx="7.347" cy="1.205" rx="1.138" ry="1.205"></ellipse></g></g><text fill="#A6A6A6" fontFamily="Roboto-Bold, Roboto" fontSize="12" fontWeight="bold"><tspan x="79" y="49">No Trades </tspan>
                                                <tspan x="79" y="63">Added</tspan></text></g>
                                    </SvgIcon>
                                    <TradeDrawer buttonName='Build a New custom strategy'
                                        stock={stock}
                                        setStock={setStock}
                                        builderData={builderData}
                                        expiryDateList={expiryDateList}
                                        expiryDate={expiryDate}
                                        setExpiryDate={setExpiryDate}
                                        handleChangeStock={handleChangeStock}
                                        nearestStrike={nearestStrike}
                                        getBuilderData={getBuilderData}
                                        setSelectTrade={setSelectTrade}
                                        selectTrade={selectTrade}
                                    />
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={7} md={7} lg={7}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: 70,
                                    mt: 4, mb: 4
                                }}
                            >
                                {selectTrade.length > 0 &&
                                    <Stack direction="row" spacing={5} sx={{ marginLeft: 3 }}>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            Est. Margin:
                                            <br /><Typography component="span" variant="body1" color="text.primary">₹{totalMargin}</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            P&L:
                                            <br /><Typography style={{ color: 'green' }} component="span" variant="body1" color="text.secondary">₹{todayPnL}</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            Max Profit:
                                            <br /><Typography sx={{ color: 'green' }} component="span" variant="body1" color="text.secondary">{maxProfit}</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            Max Loss:
                                            <br /><Typography sx={{ color: 'red' }} component="span" variant="body1" color="text.secondary">{maxLoss}</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            POP:
                                            <br /><Typography component="span" variant="body1" color="text.primary">{optionPOP}%</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            Net Credit:
                                            <br /><Typography sx={{ color: 'green' }} component="span" variant="body1" color="text.secondary">₹{netCredit}</Typography>
                                        </Typography>
                                        <Typography className={classes.marginBar} variant="body1" color="text.secondary">
                                            Breakevens:
                                            <br /><Typography component="span" variant="body1" color="text.primary">{breakEven || '0'}</Typography>
                                        </Typography>
                                    </Stack>
                                }
                            </Paper>
                            <Paper
                                sx={{
                                    p: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 400,
                                }}
                            >
                                {selectTrade.length > 0 && isChart && <Chart xChart_data={xChart_data} yChart_data={yChart_data} zChart_data={zChart_data} currentPrice={selectTrade[0].spot_price} stock={stock} />}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

export default StrategyBuilder;
