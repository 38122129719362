const authActions = {
  LOGIN: "LOGIN",
  GOOGLELOGIN: "LOGIN",
  LOGOUT: "LOGOUT",

  login: data => {
    localStorage.setItem("access_Token", data.token);
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
    };
  },

  logout: () => {
    localStorage.removeItem("access_Token");
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  }
};

export default authActions;
