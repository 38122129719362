import React from "react";
import {Outlet, Navigate} from 'react-router-dom';

export const ProtectedRoute = ({ authData, children }) => {
  const isLogin = true
  return (
    <div>
      {isLogin ? (
        <Outlet />
      ) : (
        <Navigate to={"/home"} />
      )}
    </div>
  );
};
