import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-root": {
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
      }
    },
    callLtpColor: { 
        backgroundColor: '#fffee5'
    },
    strikeBackColor: { 
        backgroundColor: '#f9f9f9'
    },
    nearestStrikeColor: { 
        backgroundColor: '#e5effb'
    },
    bsButton: { 
        width: '30px',
        height: '25px',
        fontSize: '12px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginRight: '10px',
        display: 'flex',
        webkitBoxPack: 'center',
        justifyContent: 'center',
        webkitBoxAlign: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    buybtnColor: {
        backgroundColor: '#98ce957a',
        color: 'green'
    },
    sellbtnColor: {
        backgroundColor: '#da6f6f52',
        color: 'red'
    },
    deleteIcon: { 
        cursor: 'pointer',
    },
    buybtn: { 
        width: '20px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#2dbc267a',
        color: '#626466'
    },
    sellbtn: { 
        width: '20px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: '#ce242463',
        color: '#626466'
    },

    call_oi_bar: { 
    height: '20px',
    borderRadius: '100px 0px 0px 100px',

    borderLeft: '0px',
    backgroundColor: '#ffe2e3',
    float: 'right'
    },
    put_oi_bar: { 
       height: '20px',
        borderRadius: '0px 100px 100px 0px',
        borderLeft: '0px',
        backgroundColor: '#cfefdf',
        float: 'left'
        },
    marginBar: { 
        fontSize:'13px',
        fontWeight:600,
        textAlign:'center',
        "& span": {
            fontSize:'13px',
            fontWeight:600
      }
    },
    remove_btn: { 
        border:'1px solid  !important',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        height:'24px !important',
        cursor:'pointer'
        },
    add_btn: { 
        border:'1px solid  !important',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        height:'24px !important',
        cursor:'pointer'
    },
    tradeType: { 
        border:'1px solid  !important',
        width: '40px',
        borderRadius: '4px',
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#626466'
    },
  });