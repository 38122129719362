import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStyles } from "./style"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { randomUUID } from '../../helper/methods'

export default function TradeDrawer(props) {
  const { buttonName, stock, handleChangeStock, builderData, expiryDate, setExpiryDate,
    expiryDateList, nearestStrike, getBuilderData, selectTrade, setSelectTrade } = props

  var btnStyle = { align: 'center', sm: '80%', md: '50%' }
  if (buttonName === 'Add/Edit') {
    btnStyle = { align: 'left', sm: '30%', md: '20%' }
  }
  const [open, setOpen] = useState(false);
  const [optType, setOptType] = useState('opt');
  const [ltpType, setLtpType] = useState('ltp');
  const [showBuyBtn, setShowBuyBtn] = useState('');
  const [highestCallOI, setHighestCallOI] = useState(0);
  const [highestPutOI, setHighestPutOI] = useState(0);
  const classes = useStyles();

useEffect(() => {
  let callOiMaxValue = Number.MIN_VALUE;
  let putOiMaxValue = Number.MIN_VALUE;
    for (let i = 0; i < builderData.length; i++) {
        if (builderData[i].call_oi > callOiMaxValue) {
          callOiMaxValue = builderData[i].call_oi;
        }
        if (builderData[i].put_oi > putOiMaxValue) {
          putOiMaxValue = builderData[i].put_oi;
        }
    }
    setHighestCallOI(((callOiMaxValue * 25) / 100000).toFixed(2))
    setHighestPutOI(((putOiMaxValue * 25) / 100000).toFixed(2))
}, [builderData]);

  const handleChangeOpt = (event) => {
    setOptType(event.target.value);
  };


  const handleChangeLtp = (event) => {
    setLtpType(event.target.value);
  };


  const handleChangeTradeDate = (event) => {
    let expiryDate = event.target.value
    setExpiryDate(expiryDate);
    getBuilderData(stock, expiryDate)
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    if (newOpen) {
      getBuilderData(stock, expiryDate)
    }
  };

  const checkValues = (data) => () => {
    let selectedTrade = [...selectTrade, data]
    setSelectTrade([...selectTrade, data]);
    localStorage.setItem("selectedTrade", JSON.stringify(selectedTrade));
  };

  const DrawerList = (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        width: 620,
      }}
    >
      <Container sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Paper >
              <Select
                sx={{
                  p: 2,
                  display: 'flex',
                  height: 40,
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stock}
                onChange={handleChangeStock}
                readOnly
              >
                <MenuItem value={'NIFTY'}>NIFTY</MenuItem>
                <MenuItem value={'BANKNIFTY'}>BANKNIFTY</MenuItem>
                <MenuItem value={'FINNIFTY'}>FINNIFTY</MenuItem>
              </Select>
            </Paper>

          </Grid>
          <Grid item xs={5}>
            <Button variant="outlined" sx={{ height: 40, marginRight: 2 }}>Clear</Button>
            <Button variant="contained" sx={{ height: 40 }} onClick={toggleDrawer(false)}>Done</Button>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 1,
                height: 50,
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={optType}
                exclusive
                onChange={handleChangeOpt}
                aria-label="Platform"
                sx={{
                  height: 1,
                  marginLeft: 2
                }}
              >
                <ToggleButton value="opt">OPT</ToggleButton>
                <ToggleButton value="fut">FUT</ToggleButton>
              </ToggleButtonGroup>
              <Select
                sx={{
                  marginLeft: 5,
                  height: 33,
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={expiryDate}
                onChange={handleChangeTradeDate}
              >
                {expiryDateList.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data}>{data}</MenuItem>
                  );
                })}
              </Select>
              <ToggleButtonGroup
                color="primary"
                value={ltpType}
                exclusive
                onChange={handleChangeLtp}
                aria-label="Platform"
                sx={{
                  height: 1,
                  marginLeft: 5
                }}
              >
                <ToggleButton value="ltp">LTP</ToggleButton>
                <ToggleButton value="ol">Ol</ToggleButton>
                <ToggleButton value="greeks">Greeks</ToggleButton>
              </ToggleButtonGroup>

            </Paper>
            <Divider />
            <Paper>
              <TableContainer sx={{ maxHeight: 590 }}>
                <Table stickyHeader aria-label="sticky table" className={classes.table} size="small">
                  <TableHead>
                    <TableRow sx={{ display: ltpType === 'ltp' ? '' : 'none' }}>
                      <TableCell>Call Delta</TableCell>
                      <TableCell>Call LTP</TableCell>
                      <TableCell>Strike</TableCell>
                      <TableCell>Put LTP</TableCell>
                      <TableCell>Put Delta</TableCell>
                    </TableRow>
                    <TableRow sx={{ display: ltpType === 'ol' ? '' : 'none' }}>
                      <TableCell>Call OI(chg%)</TableCell>
                      <TableCell>Strike</TableCell>
                      <TableCell sx={{textAlign: 'end'}}>Put OI(chg%)</TableCell>
                    </TableRow>
                    <TableRow sx={{ display: ltpType === 'greeks' ? '' : 'none' }}>
                      <TableCell>Call Delta</TableCell>
                      <TableCell>Strike</TableCell>
                      <TableCell>Put Delta</TableCell>
                      <TableCell>Theta</TableCell>
                      <TableCell>Vega</TableCell>
                      <TableCell>Gamma</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {builderData.map((column, i) => {
                      var class1 = nearestStrike > column.strike_price ? classes.callLtpColor : ''
                      var class2 = classes.strikeBackColor
                      var class3 = nearestStrike < column.strike_price ? classes.callLtpColor : ''
                      if (nearestStrike === column.strike_price) {
                        class1 = classes.nearestStrikeColor
                        class2 = classes.nearestStrikeColor
                        class3 = classes.nearestStrikeColor
                      }
                      let cellBuy = {
                        id: randomUUID(), type: 'CE', expiry: column.expiry_date, sell_buy: 'B', strike_price: column.strike_price, lot: 1,
                        price: column.call_ltp, spot_price: column.current_price, expTime: column.expTime, riskFreeRate: column.riskFreeRate, IV: column.call_IV, isChecked:true
                      }

                      let cellSell = {
                        id: randomUUID(), type: 'CE', expiry: column.expiry_date, sell_buy: 'S', strike_price: column.strike_price, lot: 1,
                        price: column.call_ltp, spot_price: column.current_price, expTime: column.expTime, riskFreeRate: column.riskFreeRate, IV: column.call_IV, isChecked:true
                      }

                      let putBuy = {
                        id: randomUUID(), type: 'PE', expiry: column.expiry_date, sell_buy: 'B', strike_price: column.strike_price, lot: 1,
                        price: column.put_ltp, spot_price: column.current_price, expTime: column.expTime, riskFreeRate: column.riskFreeRate, IV: column.put_IV, isChecked:true
                      }

                      let putSell = {
                        id: randomUUID(), type: 'PE', expiry: column.expiry_date, sell_buy: 'S', strike_price: column.strike_price, lot: 1,
                        price: column.put_ltp, spot_price: column.current_price, expTime: column.expTime, riskFreeRate: column.riskFreeRate, IV: column.put_IV, isChecked:true
                      }

                      let call_oi = ((column.call_oi * 25) / 100000).toFixed(2)
                      let call_chang_oi = ((column.call_chng_oi * 25) / 100000).toFixed(2)
                      let put_oi = ((column.put_oi * 25) / 100000).toFixed(2)
                      let put_chang_oi = ((column.put_chang_oi * 25) / 100000).toFixed(2)
                      let call_oi_width = Math.round((call_oi*100)/highestCallOI)
                      let put_oi_width = Math.round((put_oi*100)/highestPutOI)

                      return (
                        <>
                          {/* For LTP data table  */}
                          <TableRow hover role="checkbox" tabIndex={-1} key={'ltp' + i} sx={{ display: ltpType === 'ltp' ? '' : 'none' }}
                            onMouseEnter={e => { setShowBuyBtn(i) }}
                            onMouseLeave={e => { setShowBuyBtn('') }}>
                            <TableCell className={class1} > {column.call_delta}</TableCell>
                            <TableCell sx={{ display: 'flex', width: '145px' }} className={class1} >{column.call_ltp}
                              <Stack direction="row" spacing={1} sx={{ marginLeft: 1, display: i === showBuyBtn ? '' : 'none' }}>
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(cellBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(cellSell)}>S</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className={class2}>{column.strike_price}</TableCell>
                            <TableCell sx={{ display: 'flex', width: '145px' }} className={class3}>{column.put_ltp}
                              <Stack direction="row" spacing={1} sx={{ marginLeft: 1, display: i === showBuyBtn ? '' : 'none' }}>
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(putBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(putSell)}>S</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className={class3}>{column.put_delta}</TableCell>
                          </TableRow>

                          {/* For OI data table */}
                          <TableRow hover role="checkbox" tabIndex={-1} key={'ol' + i} sx={{ display: ltpType === 'ol' ? '' : 'none' }}
                            onMouseEnter={e => { setShowBuyBtn(i) }}
                            onMouseLeave={e => { setShowBuyBtn('') }}>
                            <TableCell className={class1} sx={{ width: '220px', paddingRight: '1px' }} > {call_oi}L <span style={{ color: call_chang_oi >= 0 ? 'green' : 'red' }}>({call_chang_oi}%)</span>                    
                              <Stack direction="row" spacing={1} sx={{marginLeft: 1, display: i === showBuyBtn ? 'inline-flex' : 'none' }}>
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(cellBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(cellSell)}>S</Typography>
                              </Stack>
                              <span className={classes.call_oi_bar} style={{ width:call_oi_width+'%'}}></span>
                            </TableCell>
                            <TableCell className={class2} sx={{ width: '120px' }}>{column.strike_price}</TableCell>
                            <TableCell sx={{width: '220px', paddingLeft: '1px', textAlign: 'end'}} className={class3}>
                              <Stack direction="row" spacing={1} sx={{ marginLeft: 1, display: i === showBuyBtn ? 'inline-flex' : 'none' }}>     
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(putBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(putSell)}>S</Typography>
                              </Stack>
                              {put_oi}L <span style={{ color: put_chang_oi >= 0 ? 'green' : 'red' }}>({put_chang_oi}%)</span>
                              <span className={classes.put_oi_bar} style={{ width:put_oi_width+'%'}}></span>
                            </TableCell>
                          </TableRow>

                          {/* For greeks data table */}
                          <TableRow hover role="checkbox" tabIndex={-1} key={'greeks' + i} sx={{ display: ltpType === 'greeks' ? '' : 'none' }}
                            onMouseEnter={e => { setShowBuyBtn(i) }}
                            onMouseLeave={e => { setShowBuyBtn('') }}>
                            <TableCell className={class1} sx={{ display: 'flex', width: '130px' }}> {column.call_delta}
                            <Stack direction="row" spacing={1} sx={{ marginLeft: 1, display: i === showBuyBtn ? '' : 'none' }}>
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(cellBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(cellSell)}>S</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className={class2}>{column.strike_price}</TableCell>
                            <TableCell className={class3} sx={{ display: 'flex', width: '130px'  }}>{column.put_delta}
                            <Stack direction="row" spacing={1} sx={{ marginLeft: 1, display: i === showBuyBtn ? '' : 'none' }}>
                                <Typography variant="body1" className={classes.buybtn} onClick={checkValues(putBuy)}>B</Typography>
                                <Typography variant="body1" className={classes.sellbtn} onClick={checkValues(putSell)}>S</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className={class3}>{column.call_theta}</TableCell>
                            <TableCell className={class3}>{column.vega}</TableCell>
                            <TableCell className={class3}>{column.gamma}</TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return (
    <>
      <Button variant="contained" color="primary"
        sx={{ alignSelf: btnStyle.align, width: { sm: btnStyle.sm, md: btnStyle.md } }} onClick={toggleDrawer(true)}>{buttonName}</Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}