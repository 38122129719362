import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { StyleRoot } from "radium";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, createTheme} from '@mui/material/styles';
import AppRoutes from "./routes";
import './assets/style.css'


const App = props => {
  const defaultTheme = createTheme({ palette: 'light'});

  return (
    <Fragment>
        <ThemeProvider theme={defaultTheme}>
          <StyleRoot>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AppRoutes/>
              </PersistGate>
            </Provider>
          </StyleRoot>
        </ThemeProvider>
    </Fragment>
  );
};

export default App;

