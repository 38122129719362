import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Header from "../components/Header";
import HomePage from '../pages/HomePage';
import PageNotFound from '../pages/404_error';
import { ProtectedRoute } from './ProtectedRoute';
import {history} from "../redux/store";
import StrategyBuilder from "../layouts/builder/strategyBuilder"

const AppRoutes = () => {
  return (
    <Router>
       <Header/>
      <Routes history={history}>
         <Route path="/" element={<Navigate to="/home" />} />
         <Route element={<ProtectedRoute />}>
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/strategy-builder" element={<StrategyBuilder />} />
         </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;