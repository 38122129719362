import erf from 'math-erf'


 const ChartCalulation = (selectTrade, currentPrice) => {
    const start_x_price = Math.round(currentPrice) - 1500
    const x_value_data = [start_x_price]
    var x_value = start_x_price
    const y_value_data = []
    const z_value_data = []
    var total_margin = 0
    var is_sell_margin = false
    var P_O_P = ''

    function normalCdf(x) {
      return (1.0 +erf(x / Math.sqrt(2.0))) / 2.0;
    }
    
    function blackScholesCall(stockPrice, strikePrice, expTime, riskFreeRate, volatility) {
      const S = parseFloat(stockPrice);
      const K = parseFloat(strikePrice);
      const T = parseFloat(expTime)/365;
      const r = parseFloat(riskFreeRate) / 100;
      const sigma = parseFloat(volatility) / 100;
      const d1 = (Math.log(S / K) + (r + (sigma ** 2) / 2) * T) / (sigma * Math.sqrt(T));
      const d2 = d1 - sigma * Math.sqrt(T);
      const call = S * normalCdf(d1) - K * Math.exp(-r * T) * normalCdf(d2);
      return call
    }

    function blackScholesPut(stockPrice, strikePrice, expTime, riskFreeRate, volatility) {
      const S = parseFloat(stockPrice);
      const K = parseFloat(strikePrice);
      const T = parseFloat(expTime)/365;
      const r = parseFloat(riskFreeRate) / 100;
      const sigma = parseFloat(volatility) / 100;
      const d1 = (Math.log(S / K) + (r + (sigma ** 2) / 2) * T) / (sigma * Math.sqrt(T));
      const d2 = d1 - sigma * Math.sqrt(T);
      const put = K * Math.exp(-r * T) * normalCdf(-d2) - S * normalCdf(-d1);
      return put
    }
    

    function calculatePOP(s0, x, r, vol, years_to_maturity, y=0.0) {   
         s0 = parseFloat(s0); x= parseFloat(x); r = parseFloat(r); vol = parseFloat(vol); years_to_maturity = parseFloat(years_to_maturity)
        let d1 = (Math.log(s0 / x) + (r - y + vol * vol / 2.0) * years_to_maturity) / (vol * Math.sqrt(years_to_maturity))
        let d2 = d1 - vol * Math.sqrt(years_to_maturity)     
        const  pop =  (normalCdf(d2) * 100).toFixed(2)
        return pop;
    }
    
    function closestToZero(arr) {
      // Filter out non-numeric values and sort the array
      const sorted = arr
          .filter(Number.isFinite) // Keep only finite numbers
          .sort((a, b) => Math.abs(a) - Math.abs(b)); // Sort by absolute value
  
      // Return the closest value to zero, or null if the array is empty
      return sorted.length > 0 ? sorted[0] : null;
  }

    for (let i = 0; i < 3000; i++) {
        var total_P_and_L = 0
        var today_P_and_L = 0
        var margin_cal = 0
        var isChartView = false
        for (let j = 0; j < selectTrade.length; j++) {
          if (selectTrade[j].isChecked === true){
            let tradePrice = parseFloat(selectTrade[j].price)
            var P_and_L = 0
            isChartView = true
            if (selectTrade[j].type === 'CE'){
              if (selectTrade[j].sell_buy === 'B'){
                 P_and_L = Math.round(((x_value-selectTrade[j].strike_price)-tradePrice)*(selectTrade[j].lot * 25))
                if (x_value <= selectTrade[j].strike_price){
                  P_and_L = Math.round(((selectTrade[j].strike_price-selectTrade[j].strike_price)-tradePrice)*(selectTrade[j].lot * 25))
                }
                total_P_and_L += P_and_L
                let callOptPrem = blackScholesCall(x_value, selectTrade[j].strike_price, selectTrade[j].expTime, selectTrade[j].riskFreeRate, selectTrade[j].IV)
                today_P_and_L += ((callOptPrem - tradePrice)*selectTrade[j].lot*25)
                
              }else{
                 P_and_L = Math.round(((selectTrade[j].strike_price-x_value)+tradePrice)*(selectTrade[j].lot * 25))
                if (x_value <= selectTrade[j].strike_price){
                  P_and_L = Math.round(((selectTrade[j].strike_price+tradePrice)-selectTrade[j].strike_price)*(selectTrade[j].lot * 25))
                }
                  total_P_and_L += P_and_L
                  let callOptPrem = blackScholesCall(x_value, selectTrade[j].strike_price, selectTrade[j].expTime, selectTrade[j].riskFreeRate, selectTrade[j].IV)
                  today_P_and_L += (tradePrice-callOptPrem)*selectTrade[j].lot*25
                  is_sell_margin = true
              }
    
            }else{
              if (selectTrade[j].sell_buy === 'B'){
                 P_and_L = Math.round(((selectTrade[j].strike_price-x_value)-tradePrice)*(selectTrade[j].lot * 25))
              if (selectTrade[j].strike_price <= x_value){
                  P_and_L = Math.round((selectTrade[j].strike_price-(selectTrade[j].strike_price+tradePrice))*(selectTrade[j].lot * 25))
                }
                total_P_and_L += P_and_L
                let callOptPrem = blackScholesPut(x_value, selectTrade[j].strike_price, selectTrade[j].expTime, selectTrade[j].riskFreeRate, selectTrade[j].IV)
                today_P_and_L += (callOptPrem - tradePrice)*selectTrade[j].lot*25
                
              }else{
                 P_and_L = Math.round((x_value-selectTrade[j].strike_price)+tradePrice)*(selectTrade[j].lot * 25)
                if (selectTrade[j].strike_price <= x_value){
                  P_and_L = Math.round((selectTrade[j].strike_price-(selectTrade[j].strike_price-tradePrice))*(selectTrade[j].lot * 25))
                }
                total_P_and_L += P_and_L
                let callOptPrem = blackScholesPut(x_value, selectTrade[j].strike_price, selectTrade[j].expTime, selectTrade[j].riskFreeRate, selectTrade[j].IV)
                today_P_and_L += (tradePrice-callOptPrem)*selectTrade[j].lot*25
                is_sell_margin = true
              }
    
            }
            if (total_margin === 0){
            margin_cal += tradePrice*selectTrade[j].lot*25
            P_O_P = calculatePOP(currentPrice, selectTrade[j].strike_price, 0.01, selectTrade[j].IV, selectTrade[j].expTime / 365);
            }

        }
      }
  
      if (total_margin === 0){
            total_margin = Math.round(margin_cal)
        }
    
      y_value_data.push(total_P_and_L) 
      z_value_data.push(today_P_and_L.toFixed(2))  
      x_value += 1 
      x_value_data.push(x_value)
    }

    let index_filter = x_value_data.findIndex(a => a === Math.round(currentPrice));
    let today_pnl =  z_value_data[index_filter-1] < 0 ? '0.00':z_value_data[index_filter]
    let net_credit =  y_value_data[index_filter] < 0 ? '0.00':y_value_data[index_filter]
    let getBreakVal = closestToZero(y_value_data)
    let break_index = y_value_data.findIndex(a => a === Math.round(getBreakVal));
    let breakeven = x_value_data[break_index]
    if (isChartView === false){breakeven = 0}
    let data = {x_value_data, y_value_data, z_value_data, today_pnl, breakeven, total_margin, is_sell_margin, net_credit, P_O_P, isChartView}


    return data
    
}    

export {ChartCalulation}