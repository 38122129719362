import React from "react";
import ReactECharts from 'echarts-for-react';  

export default function TradeDrawer(props) {
  const {xChart_data, yChart_data, zChart_data, currentPrice, stock} = props


 const option = {
    color: 'blue',
    // Make gradient line here
    visualMap: [{
        show: false,
        type: 'continuous',
        seriesIndex: 0,
        color: ['#2dbc267a', '#ce242463']
       
    }],
    title: [{
        left: 'center',
        text: stock
    }],
    xAxis: [{
      data: xChart_data,
        axisPointer: {
        label:{
            color:['#5470c6'],
            }
        },
        axisLine: { onZero: false,
          lineStyle: {
            color: 'black',
            width: '1',
          }
         }

    }],
    yAxis: [{
            type: 'value',
            axisPointer: {
            label:{
                color:['#5470c6'],
                }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'black',
                width: '1',
              },
            },
    }],
    grid: [{
      left: '2%',
      right: '2%',
      bottom: '0%',
      containLabel: true
       
    }],
    tooltip : {
        trigger: 'axis',
        type: 'cross',
        animation: false,
        backgroundColor: 'rgb(14 12 12 / 64%)', 
        textStyle: {
          color: 'rgb(237 238 239)',
          
        },
        borderRadius: 10,
        formatter: function (params) {
            var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
            let rez = '<p>When Price is at ' + params[0].axisValue + '<br/> Expected P&L</p>';
            params.forEach(item => {
               let series_name = item.seriesIndex === 0 ? 'Expiry Date':'Target(t+0)'
               let data_color = item.data > 0 ? '#22f318b8':'#e95454'
               let plus_sign = item.data > 0 ? '+':''
                var xx = '<p>'   + colorSpan(item.color) + ' ' + series_name + ': <span style=color:'+data_color+'>'+plus_sign+'' + item.data + '</span></p>'
                rez += xx;
            });
            return `<div style="font-size:12px">${rez}</div>`;
        }        
    },
    toolbox: {
      feature: {
        restore: {},
      }
    },
    dataZoom: [
      {
        type: 'inside',
        // start: 38,
        // end: 65
      }
    ],
    series: [{
        type: 'line',
        showSymbol: false,
        data: yChart_data,
        itemStyle: {},
        markLine : {
          silent: true, 
          data : [
            {type : 'average', name: 'Marker Line', valueIndex: 0, xAxis:(''+Math.round(currentPrice)), itemStyle:{normal:{color:'darkgreen'}},
            label: {
              normal: {
                show: true,
                position: 'end',
                formatter: 'Current price: ' + currentPrice
              }
            }
          },
          ]
        },
     smooth: true,
    areaStyle: {
         opacity: 1
        }
  
    },
    {
      type: 'line',
      showSymbol: false,
      data: zChart_data,
   smooth: true,
      lineStyle:{
          normal: {
            color: 'blue',
            width: 2,
            type: 'dashed'
          }
      },
  }
]
};
  return (
    <ReactECharts
  option={option}
  notMerge={true}
  lazyUpdate={true}
  theme={"theme_name"}
    />
  );
}